import React, { useEffect, useState } from 'react';
import { Modal } from 'antd';
import { PainLevelRadioGroup, IRadioOption } from 'common/components/TreatmentFeedback/PainLevelRadioGroup';
import { NavigationButton } from 'common/components/TreatmentFeedback/NavigationButton';
import { ReactComponent as ArrowDown } from 'app/assets/images/svg/arrowDown.svg';
import { ReactComponent as PainSeverityScale } from 'app/assets/images/svg/painSeverityScale.svg';
import { IQuestionnaireElement } from 'entities/PatientSessions/PatientSession.models';

interface IComponentProps {
  question: IQuestionnaireElement;
  defaultGroupLabel?: string;
  optionsGroup: IRadioOption[];
  onSelect: (questionId: string, value: string | undefined) => void;
  showNRSImage?: boolean;
}

const defaultLabelColor = '#A0ACBA';

export const PainLevelSelector: React.FC<IComponentProps> = ({
  question,
  onSelect,
  defaultGroupLabel,
  optionsGroup,
  showNRSImage = false,
}) => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  // selected but NOT saved answer
  const [selectedAnswer, setSelectedAnswer] = useState<string | undefined>(undefined);
  // selected AND saved answer
  const [submittedAnswer, setSubmittedAnswer] = useState<string | undefined>(undefined);
  const [answerColor, setSubmittedAnswerColor] = useState<string | undefined>(defaultLabelColor);

  const handleSelect = () => {
    // get previous or new answer
    const answerToSet = selectedAnswer || submittedAnswer;
    const textColor = optionsGroup.find((option) => option.value === answerToSet)?.bgColor;

    setSubmittedAnswer(answerToSet);
    setSubmittedAnswerColor(textColor);
    onSelect(question.textId, answerToSet);
    closeModal();
  };

  const handlePainLevelChange = (answer: string) => {
    setSelectedAnswer(answer);
  };

  const closeModal = () => {
    setSelectedAnswer(undefined);
    setIsModalOpen(false);
  };

  const getSelectorLabel = () => {
    if (submittedAnswer) {
      const option = optionsGroup.find((option) => option.value === submittedAnswer);

      if (defaultGroupLabel) {
        return <span style={{ color: answerColor }}>{option?.label}</span>;
      } else {
        return (
          <>
            <span style={{ color: defaultLabelColor }}>{question.question}: </span>
            <span style={{ color: answerColor }}>{option?.label}</span>
          </>
        );
      }
    } else {
      if (defaultGroupLabel) {
        return <span style={{ color: defaultLabelColor }}>{defaultGroupLabel}</span>;
      } else {
        return <span style={{ color: defaultLabelColor }}>{question.question}: </span>;
      }
    }
  };

  useEffect(() => {
    setSelectedAnswer(undefined);
    setSubmittedAnswer(undefined);
    setSubmittedAnswerColor(defaultLabelColor);
  }, [optionsGroup]);

  return (
    <>
      <div onClick={() => setIsModalOpen(true)} className="pain-level-selector__wrapper">
        <div>{getSelectorLabel()}</div>
        <ArrowDown />
      </div>
      <Modal
        title={defaultGroupLabel || question.question}
        visible={isModalOpen}
        onCancel={closeModal}
        footer={null}
        className="pain-level-selector__modal"
        destroyOnClose={true}
      >
        {showNRSImage && <PainSeverityScale className="mt-8" />}
        <PainLevelRadioGroup radioOptions={optionsGroup} handleChange={handlePainLevelChange} submittedAnswer={submittedAnswer} />
        <div className="navigation-button-wrapper">
          <NavigationButton label="Select" onClick={handleSelect} disabled={!selectedAnswer && !submittedAnswer} />
        </div>
      </Modal>
    </>
  );
};
