import { ECommonBodyParts } from 'common/const/bodyAreas.const';
import {
  EChestAbdomenBack,
  EHeadNeck,
  ELeftArm,
  ELeftFoot,
  ELeftHand,
  ELeftLeg,
  ERightArm,
  ERightFoot,
  ERightHand,
  ERightLeg,
} from 'common/const/painAreas/bodyParts.enums';

export const painAreasProjections: { [key: string]: string[] } = {
  [ECommonBodyParts.HeadNeck]: [
    EHeadNeck['Head/Neck (Front)'],
    EHeadNeck['Head/Neck (Back)'],
    EHeadNeck['Head/Neck (Right)'],
    EHeadNeck['Head/Neck (Left)'],
    EHeadNeck['Head/Neck (Top)'],
  ],
  [ECommonBodyParts.RightArm]: [ERightArm['Right Arm (Front)'], ERightArm['Right Arm (Back)']],
  [ECommonBodyParts.LeftArm]: [ELeftArm['Left Arm (Front)'], ELeftArm['Left Arm (Back)']],
  [ECommonBodyParts.RightHand]: [ERightHand['Right Hand (Front)'], ERightHand['Right Hand (Back)']],
  [ECommonBodyParts.LeftHand]: [ELeftHand['Left Hand (Front)'], ELeftHand['Left Hand (Back)']],
  [ECommonBodyParts.ChestAbdomenBack]: [
    EChestAbdomenBack['Chest/Abdomen'],
    EChestAbdomenBack['Back'],
    EChestAbdomenBack['Left'],
    EChestAbdomenBack['Right'],
  ],
  [ECommonBodyParts.LeftLeg]: [ELeftLeg['Left Leg (Front)'], ELeftLeg['Left Leg (Back)'], ELeftLeg['Left Leg (Side)']],
  [ECommonBodyParts.RightLeg]: [ERightLeg['Right Leg (Front)'], ERightLeg['Right Leg (Back)'], ERightLeg['Right Leg (Side)']],
  [ECommonBodyParts.LeftFoot]: [ELeftFoot['Left Foot (Front)'], ELeftFoot['Left Foot (Back)']],
  [ECommonBodyParts.RightFoot]: [ERightFoot['Right Foot (Front)'], ERightFoot['Right Foot (Back)']],
};
