import React, { useEffect, useState } from 'react';
import { Layout } from 'antd';
import isEqual from 'lodash.isequal';
import { FormInstance } from 'antd/lib/form/Form';
import { EQuestionnaireBodyAreas } from 'common/const/questionnaire.const';
import {
  IFormValues,
  IPatientAnswer,
  IQuestionnaireAnswer,
  IQuestionnaireBodyArea,
  IBaseParams,
  IValueChangeType,
} from 'common/models/formBuilder.models';
import { useTimer } from 'common/hooks/useTimer';
import { QUESTIONNAIRE_UPDATE_INTERVAL } from 'common/config';
import { getTableQuestionnaire } from 'common/helpers/tableFormBuilder.helper';
import { tableFormBuilderMapper } from 'common/mappers/tableFormBuilder.mapper';
import { IQuestionnaireElement, IQuestionnaireModel } from 'entities/PatientSessions/PatientSession.models';

interface IComponentProps {
  questionnaire: IQuestionnaireModel;
  handleSubmit: (params: IBaseParams, update?: boolean) => void;
  patientAnswers?: IPatientAnswer[];
}

export const TableFormBuilder: React.FC<IComponentProps> = (props) => {
  const { questionnaire, handleSubmit, patientAnswers } = props;

  const questionnareAnswers = questionnaire.elements.find(
    (element: IQuestionnaireElement) => element.answers?.length || !element.textId.length
  )?.answers;
  const data = questionnareAnswers?.map((item: string, index: number) => {
    return { key: index, pain_area: (EQuestionnaireBodyAreas as IQuestionnaireBodyArea)[item], item };
  });
  const questionnaireId = questionnaire.textId;
  const name = questionnaire.name;

  const [answers, setAnswers] = useState<IQuestionnaireAnswer[]>([]);
  const [answersCopy, setAnswersCopy] = useState<IQuestionnaireAnswer[]>([]);
  const [comment, setComment] = useState<string>(questionnaire?.comments[0]?.comment || '');
  const [commentCopy, setCommentCopy] = useState<string>(questionnaire?.comments[0]?.comment || '');
  const [timer, timerLimit, flush] = useTimer(QUESTIONNAIRE_UPDATE_INTERVAL);
  const [formController, setFormController] = useState<FormInstance<IFormValues>>();

  const handleSave = (comment: string) => {
    setComment(comment);
  };

  const handleChange = (values: IFormValues, formController: FormInstance<IFormValues>, type?: IValueChangeType) => {
    const answers: IQuestionnaireAnswer[] = Object.entries(values).map((value) => {
      const element = questionnaire.elements.find((element: IQuestionnaireElement) => element.textId === value[0]);

      // If no element found - then a custom item was added
      const question: string = element
        ? element.question
        : value[1]
        ? (value[1][`${value[0]}_question` as keyof typeof value[1]] as string)
        : '';

      return tableFormBuilderMapper(questionnaireId, element, value, question, type);
    });

    if (type?.userDefined) {
      answers.push({
        questionId: Object.keys(type.userDefined)[0],
        answers: {},
        question: Object.values(type.userDefined)[0],
        isUserDefined: true,
      });
    }

    setAnswers(answers);
    setFormController(formController);

    type && flush();
  };

  const onSubmit = () => {
    handleSubmit({ elements: answers, comments: [{ textId: questionnaireId, comment }] });
  };

  useEffect(() => {
    if (formController && timer === timerLimit && (!isEqual(answers, answersCopy) || !isEqual(comment, commentCopy))) {
      const fieldsWithErrors = formController
        .getFieldsError()
        .filter((field) => field.errors.length && !field.errors.includes('Please fill in the required field'))
        .map((field) => field.name)
        .flat();

      let elements = answers;

      // Save medications list data even if we have validation errors
      if (questionnaireId !== 'baseline_medications_list') {
        elements = answers.filter((answer: IQuestionnaireAnswer) => !fieldsWithErrors.includes(answer.questionId));
      }

      handleSubmit({ elements, comments: [{ textId: questionnaire.textId, comment }] }, true);
      setAnswersCopy(answers);
      setCommentCopy(comment);
    }
  }, [timer]);

  return (
    <Layout>
      {getTableQuestionnaire(questionnaire, questionnaireId, name, data, onSubmit, handleSave, handleChange, patientAnswers)}
    </Layout>
  );
};
