import { ECommonBodyParts } from 'common/const/bodyAreas.const';
import { IBodyParts } from 'common/models/painAreas.models';
import {
  EChestAbdomenBack,
  EHeadNeck,
  ELeftArm,
  ELeftFoot,
  ELeftHand,
  ELeftLeg,
  ERightArm,
  ERightFoot,
  ERightHand,
  ERightLeg,
} from 'common/const/painAreas/bodyParts.enums';
import { heatmapAreas } from 'common/const/painAreas/painAreasHeatmaps.const';
import headNeckFront from 'app/assets/images/painAreas/male/headNeckFront.png';
import headNeckFrontHeatmap from 'app/assets/images/painAreas/male/heatmaps/headNeckFront.heatmap.png';
import headNeckBack from 'app/assets/images/painAreas/male/headNeckBack.png';
import headNeckBackHeatmap from 'app/assets/images/painAreas/male/heatmaps/headNeckBack.heatmap.png';
import headNeckLeft from 'app/assets/images/painAreas/male/headNeckLeft.png';
import headNeckLeftHeatmap from 'app/assets/images/painAreas/male/heatmaps/headNeckLeft.heatmap.png';
import headNeckRight from 'app/assets/images/painAreas/male/headNeckRight.png';
import headNeckRightHeatmap from 'app/assets/images/painAreas/male/heatmaps/headNeckRight.heatmap.png';
import headNeckTop from 'app/assets/images/painAreas/male/headNeckTop.png';
import headNeckTopHeatmap from 'app/assets/images/painAreas/male/heatmaps/headNeckTop.heatmap.png';
import rightArmFront from 'app/assets/images/painAreas/male/rightArmFront.png';
import rightArmFrontHeatmap from 'app/assets/images/painAreas/male/heatmaps/rightArmFront.heatmap.png';
import rightArmBack from 'app/assets/images/painAreas/male/rightArmBack.png';
import rightArmBackHeatmap from 'app/assets/images/painAreas/male/heatmaps/rightArmBack.heatmap.png';
import leftArmFront from 'app/assets/images/painAreas/male/leftArmFront.png';
import leftArmFrontHeatmap from 'app/assets/images/painAreas/male/heatmaps/leftArmFront.heatmap.png';
import leftArmBack from 'app/assets/images/painAreas/male/leftArmBack.png';
import leftArmBackHeatmap from 'app/assets/images/painAreas/male/heatmaps/leftArmBack.heatmap.png';
import rightHandFront from 'app/assets/images/painAreas/male/rightHandFront.png';
import rightHandFrontHeatmap from 'app/assets/images/painAreas/male/heatmaps/rightHandFront.heatmap.png';
import rightHandBack from 'app/assets/images/painAreas/male/rightHandBack.png';
import rightHandBackHeatmap from 'app/assets/images/painAreas/male/heatmaps/rightHandBack.heatmap.png';
import leftHandFront from 'app/assets/images/painAreas/male/leftHandFront.png';
import leftHandFrontHeatmap from 'app/assets/images/painAreas/male/heatmaps/leftHandFront.heatmap.png';
import leftHandBack from 'app/assets/images/painAreas/male/leftHandBack.png';
import leftHandBackHeatmap from 'app/assets/images/painAreas/male/heatmaps/leftHandBack.heatmap.png';
import chestAbdomen from 'app/assets/images/painAreas/male/chestAbdomen.png';
import chestAbdomenHeatmap from 'app/assets/images/painAreas/male/heatmaps/chestAbdomen.heatmap.png';
import back from 'app/assets/images/painAreas/male/back.png';
import backHeatmap from 'app/assets/images/painAreas/male/heatmaps/back.heatmap.png';
import right from 'app/assets/images/painAreas/male/right.png';
import rightHeatmap from 'app/assets/images/painAreas/male/heatmaps/right.heatmap.png';
import left from 'app/assets/images/painAreas/male/left.png';
import leftHeatmap from 'app/assets/images/painAreas/male/heatmaps/left.heatmap.png';
import leftLegFront from 'app/assets/images/painAreas/male/leftLegFront.png';
import leftLegBack from 'app/assets/images/painAreas/male/leftLegBack.png';
import leftLegSide from 'app/assets/images/painAreas/male/leftLegSide.png';
import leftLegFrontHeatmap from 'app/assets/images/painAreas/male/heatmaps/leftLegFront.heatmap.png';
import leftLegBackHeatmap from 'app/assets/images/painAreas/male/heatmaps/leftLegBack.heatmap.png';
import leftLegSideHeatmap from 'app/assets/images/painAreas/male/heatmaps/leftLegSide.heatmap.png';
import rightLegFront from 'app/assets/images/painAreas/male/rightLegFront.png';
import rightLegBack from 'app/assets/images/painAreas/male/rightLegBack.png';
import rightLegSide from 'app/assets/images/painAreas/male/rightLegSide.png';
import rightLegFrontHeatmap from 'app/assets/images/painAreas/male/heatmaps/rightLegFront.heatmap.png';
import rightLegBackHeatmap from 'app/assets/images/painAreas/male/heatmaps/rightLegBack.heatmap.png';
import rightLegSideHeatmap from 'app/assets/images/painAreas/male/heatmaps/rightLegSide.heatmap.png';
import leftFootFront from 'app/assets/images/painAreas/male/leftFootFront.png';
import leftFootFrontHeatmap from 'app/assets/images/painAreas/male/heatmaps/leftFootFront.heatmap.png';
import leftFootBack from 'app/assets/images/painAreas/male/leftFootBack.png';
import leftFootBackHeatmap from 'app/assets/images/painAreas/male/heatmaps/leftFootBack.heatmap.png';
import rightFootFront from 'app/assets/images/painAreas/male/rightFootFront.png';
import rightFootFrontHeatmap from 'app/assets/images/painAreas/male/heatmaps/rightFootFront.heatmap.png';
import rightFootBack from 'app/assets/images/painAreas/male/rightFootBack.png';
import rightFootBackHeatmap from 'app/assets/images/painAreas/male/heatmaps/rightFootBack.heatmap.png';

export const maleAreas: IBodyParts = {
  [ECommonBodyParts.HeadNeck]: [
    {
      name: EHeadNeck['Head/Neck (Front)'],
      image: headNeckFront,
      heatmap: headNeckFrontHeatmap,
      heatmapData: heatmapAreas[EHeadNeck['Head/Neck (Front)']],
      canvasData: '',
      comments: '',
      selectedAreas: [],
      dataURL: '',
    },
    {
      name: EHeadNeck['Head/Neck (Back)'],
      image: headNeckBack,
      heatmap: headNeckBackHeatmap,
      heatmapData: heatmapAreas[EHeadNeck['Head/Neck (Back)']],
      canvasData: '',
      comments: '',
      selectedAreas: [],
      dataURL: '',
    },
    {
      name: EHeadNeck['Head/Neck (Right)'],
      image: headNeckRight,
      heatmap: headNeckRightHeatmap,
      heatmapData: heatmapAreas[EHeadNeck['Head/Neck (Right)']],
      canvasData: '',
      comments: '',
      selectedAreas: [],
      dataURL: '',
    },
    {
      name: EHeadNeck['Head/Neck (Left)'],
      image: headNeckLeft,
      heatmap: headNeckLeftHeatmap,
      heatmapData: heatmapAreas[EHeadNeck['Head/Neck (Left)']],
      canvasData: '',
      comments: '',
      selectedAreas: [],
      dataURL: '',
    },
    {
      name: EHeadNeck['Head/Neck (Top)'],
      image: headNeckTop,
      heatmap: headNeckTopHeatmap,
      heatmapData: heatmapAreas[EHeadNeck['Head/Neck (Top)']],
      canvasData: '',
      comments: '',
      selectedAreas: [],
      dataURL: '',
    },
  ],
  [ECommonBodyParts.RightArm]: [
    {
      name: ERightArm['Right Arm (Front)'],
      image: rightArmFront,
      heatmap: rightArmFrontHeatmap,
      heatmapData: heatmapAreas[ERightArm['Right Arm (Front)']],
      canvasData: '',
      comments: '',
      selectedAreas: [],
      dataURL: '',
    },
    {
      name: ERightArm['Right Arm (Back)'],
      image: rightArmBack,
      heatmap: rightArmBackHeatmap,
      heatmapData: heatmapAreas[ERightArm['Right Arm (Back)']],
      canvasData: '',
      comments: '',
      selectedAreas: [],
      dataURL: '',
    },
  ],
  [ECommonBodyParts.LeftArm]: [
    {
      name: ELeftArm['Left Arm (Front)'],
      image: leftArmFront,
      heatmap: leftArmFrontHeatmap,
      heatmapData: heatmapAreas[ELeftArm['Left Arm (Front)']],
      canvasData: '',
      comments: '',
      selectedAreas: [],
      dataURL: '',
    },
    {
      name: ELeftArm['Left Arm (Back)'],
      image: leftArmBack,
      heatmap: leftArmBackHeatmap,
      heatmapData: heatmapAreas[ELeftArm['Left Arm (Back)']],
      canvasData: '',
      comments: '',
      selectedAreas: [],
      dataURL: '',
    },
  ],
  [ECommonBodyParts.RightHand]: [
    {
      name: ERightHand['Right Hand (Front)'],
      image: rightHandFront,
      heatmap: rightHandFrontHeatmap,
      heatmapData: heatmapAreas[ERightHand['Right Hand (Front)']],
      canvasData: '',
      comments: '',
      selectedAreas: [],
      dataURL: '',
    },
    {
      name: ERightHand['Right Hand (Back)'],
      image: rightHandBack,
      heatmap: rightHandBackHeatmap,
      heatmapData: heatmapAreas[ERightHand['Right Hand (Back)']],
      canvasData: '',
      comments: '',
      selectedAreas: [],
      dataURL: '',
    },
  ],
  [ECommonBodyParts.LeftHand]: [
    {
      name: ELeftHand['Left Hand (Front)'],
      image: leftHandFront,
      heatmap: leftHandFrontHeatmap,
      heatmapData: heatmapAreas[ELeftHand['Left Hand (Front)']],
      canvasData: '',
      comments: '',
      selectedAreas: [],
      dataURL: '',
    },
    {
      name: ELeftHand['Left Hand (Back)'],
      image: leftHandBack,
      heatmap: leftHandBackHeatmap,
      heatmapData: heatmapAreas[ELeftHand['Left Hand (Back)']],
      canvasData: '',
      comments: '',
      selectedAreas: [],
      dataURL: '',
    },
  ],
  [ECommonBodyParts.ChestAbdomenBack]: [
    {
      name: EChestAbdomenBack['Chest/Abdomen'],
      image: chestAbdomen,
      heatmap: chestAbdomenHeatmap,
      heatmapData: heatmapAreas[EChestAbdomenBack['Chest/Abdomen']],
      canvasData: '',
      comments: '',
      selectedAreas: [],
      dataURL: '',
    },
    {
      name: EChestAbdomenBack['Back'],
      image: back,
      heatmap: backHeatmap,
      heatmapData: heatmapAreas[EChestAbdomenBack['Back']],
      canvasData: '',
      comments: '',
      selectedAreas: [],
      dataURL: '',
    },
    {
      name: EChestAbdomenBack['Left'],
      image: left,
      heatmap: leftHeatmap,
      heatmapData: heatmapAreas[EChestAbdomenBack['Left']],
      canvasData: '',
      comments: '',
      selectedAreas: [],
      dataURL: '',
    },
    {
      name: EChestAbdomenBack['Right'],
      image: right,
      heatmap: rightHeatmap,
      heatmapData: heatmapAreas[EChestAbdomenBack['Right']],
      canvasData: '',
      comments: '',
      selectedAreas: [],
      dataURL: '',
    },
  ],
  [ECommonBodyParts.LeftLeg]: [
    {
      name: ELeftLeg['Left Leg (Front)'],
      image: leftLegFront,
      heatmap: leftLegFrontHeatmap,
      heatmapData: heatmapAreas[ELeftLeg['Left Leg (Front)']],
      canvasData: '',
      comments: '',
      selectedAreas: [],
      dataURL: '',
    },
    {
      name: ELeftLeg['Left Leg (Back)'],
      image: leftLegBack,
      heatmap: leftLegBackHeatmap,
      heatmapData: heatmapAreas[ELeftLeg['Left Leg (Back)']],
      canvasData: '',
      comments: '',
      selectedAreas: [],
      dataURL: '',
    },
    {
      name: ELeftLeg['Left Leg (Side)'],
      image: leftLegSide,
      heatmap: leftLegSideHeatmap,
      heatmapData: heatmapAreas[ELeftLeg['Left Leg (Side)']],
      canvasData: '',
      comments: '',
      selectedAreas: [],
      dataURL: '',
    },
  ],
  [ECommonBodyParts.RightLeg]: [
    {
      name: ERightLeg['Right Leg (Front)'],
      image: rightLegFront,
      heatmap: rightLegFrontHeatmap,
      heatmapData: heatmapAreas[ERightLeg['Right Leg (Front)']],
      canvasData: '',
      comments: '',
      selectedAreas: [],
      dataURL: '',
    },
    {
      name: ERightLeg['Right Leg (Back)'],
      image: rightLegBack,
      heatmap: rightLegBackHeatmap,
      heatmapData: heatmapAreas[ERightLeg['Right Leg (Back)']],
      canvasData: '',
      comments: '',
      selectedAreas: [],
      dataURL: '',
    },
    {
      name: ERightLeg['Right Leg (Side)'],
      image: rightLegSide,
      heatmap: rightLegSideHeatmap,
      heatmapData: heatmapAreas[ERightLeg['Right Leg (Side)']],
      canvasData: '',
      comments: '',
      selectedAreas: [],
      dataURL: '',
    },
  ],
  [ECommonBodyParts.LeftFoot]: [
    {
      name: ELeftFoot['Left Foot (Front)'],
      image: leftFootFront,
      heatmap: leftFootFrontHeatmap,
      heatmapData: heatmapAreas[ELeftFoot['Left Foot (Front)']],
      canvasData: '',
      comments: '',
      selectedAreas: [],
      dataURL: '',
    },
    {
      name: ELeftFoot['Left Foot (Back)'],
      image: leftFootBack,
      heatmap: leftFootBackHeatmap,
      heatmapData: heatmapAreas[ELeftFoot['Left Foot (Back)']],
      canvasData: '',
      comments: '',
      selectedAreas: [],
      dataURL: '',
    },
  ],
  [ECommonBodyParts.RightFoot]: [
    {
      name: ERightFoot['Right Foot (Front)'],
      image: rightFootFront,
      heatmap: rightFootFrontHeatmap,
      heatmapData: heatmapAreas[ERightFoot['Right Foot (Front)']],
      canvasData: '',
      comments: '',
      selectedAreas: [],
      dataURL: '',
    },
    {
      name: ERightFoot['Right Foot (Back)'],
      image: rightFootBack,
      heatmap: rightFootBackHeatmap,
      heatmapData: heatmapAreas[ERightFoot['Right Foot (Back)']],
      canvasData: '',
      comments: '',
      selectedAreas: [],
      dataURL: '',
    },
  ],
};
