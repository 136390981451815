import React, { useEffect, useState } from 'react';
import { Checkbox, Form, Layout } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import isEqual from 'lodash.isequal';
import { IQuestionnaireAnswer, IFormValues, IPatientAnswer, IBaseParams } from 'common/models/formBuilder.models';
import { QUESTIONNAIRE_UPDATE_INTERVAL } from 'common/config';
import { useTimer } from 'common/hooks/useTimer';
import { renderFormControls } from 'common/helpers/diaryFormBuilder.helper';
import { DiaryHeadNonHeadQuestionnairesId, ignoredQuestionTypesWithErrors } from 'common/const/questionnaire.const';
import { DiaryNavButtons } from 'common/components/DiaryNavButtons';
import { ReactComponent as NRSPainScale } from 'app/assets/images/svg/NRSPainScale.svg';
import { IQuestionnaireElement, IQuestionnaireModel } from 'entities/PatientSessions/PatientSession.models';

interface IComponentProps {
  questionnaire: IQuestionnaireModel;
  patientAnswers?: IPatientAnswer[];
  handleSubmit: (params: IBaseParams, update?: boolean) => void;
}

export const DiaryFormBuilder: React.FC<IComponentProps> = (props) => {
  const { questionnaire, handleSubmit, patientAnswers } = props;
  const [answers, setAnswers] = useState<IFormValues[]>([]);
  const [answersCopy, setAnswersCopy] = useState<IFormValues[]>(answers);
  const [formController] = useForm<IFormValues[]>();
  const [timer, timerLimit] = useTimer(QUESTIONNAIRE_UPDATE_INTERVAL);
  const [hadPain, setHadPain] = useState<boolean>(!questionnaire.elements[0].patientAnswers);
  const [isHeadNonHeadQuestionnaire] = useState<boolean>(DiaryHeadNonHeadQuestionnairesId.includes(questionnaire.textId));
  const isHeadacheSymptoms = questionnaire.textId === 'diary_head_symptoms';

  const createAnswersObject = () =>
    Object.entries(formController.getFieldsValue()).map((value: IFormValues) => ({
      questionId: value[0],
      question: questionnaire.elements.find((element: IQuestionnaireElement) => element.textId === value[0])?.question,
      answers: value[1],
    }));

  const onSubmit = () => {
    handleSubmit({ elements: createAnswersObject() });
  };

  useEffect(() => {
    if (timer === timerLimit && !isEqual(answers, answersCopy)) {
      const fieldsWithErrors = formController
        .getFieldsError()
        .filter((field) => field.errors.length)
        .map((field) => field.name)
        .flat();

      const elements = createAnswersObject().filter((answer: IQuestionnaireAnswer) => {
        const questionType =
          questionnaire.elements.find((element: IQuestionnaireElement) => element.textId === answer.questionId)?.type || '';

        return ignoredQuestionTypesWithErrors.includes(questionType) ? true : !fieldsWithErrors.includes(answer.questionId);
      });

      handleSubmit({ elements }, true);
      setAnswersCopy(answers);
    }
  }, [timer]);

  useEffect(() => {
    const answers: IQuestionnaireAnswer[] = [];
    if (patientAnswers?.length) {
      patientAnswers.forEach((patientAnswer: IPatientAnswer) => {
        // Name will be an array only in case when it's a SelectGroup component answers
        if (patientAnswer.name instanceof Array) {
          const answer = answers.find((item: IQuestionnaireAnswer) => item.questionId === patientAnswer.name[0]);
          const value = { [patientAnswer.name[1]]: patientAnswer.value };

          if (answer) {
            answer.answers.push(value);
          } else {
            answers.push({ questionId: patientAnswer.name[0], answers: [value] });
          }
        } else {
          answers.push({
            questionId: patientAnswer.name,
            answers: patientAnswer.value,
          });
        }
      });
      setAnswers(answers);
    }
  }, [patientAnswers]);

  return (
    <Layout>
      <Form
        className="form"
        layout="vertical"
        onFinish={onSubmit}
        scrollToFirstError={{ behavior: 'smooth' }}
        fields={patientAnswers}
        preserve={false}
        form={formController}
        onValuesChange={() => setAnswers(formController.getFieldsValue())}
        id="form"
      >
        <h1 className="form__title">{questionnaire.name}</h1>
        {isHeadNonHeadQuestionnaire && (
          <Form.Item className="form__diary__checkbox" name={questionnaire.elements[0].textId} valuePropName="checked">
            <Checkbox
              onClick={() => {
                setHadPain(!hadPain);
              }}
              defaultChecked={!hadPain}
            >
              {questionnaire.elements[0].question}
            </Checkbox>
          </Form.Item>
        )}

        {isHeadNonHeadQuestionnaire
          ? hadPain && (
              <>
                {isHeadacheSymptoms && <NRSPainScale className="pain-questions-page__image" />}
                {renderFormControls(questionnaire.elements.slice(1), formController, isHeadNonHeadQuestionnaire)}
              </>
            )
          : renderFormControls(questionnaire.elements, formController, isHeadNonHeadQuestionnaire)}
      </Form>
      <DiaryNavButtons />
    </Layout>
  );
};
