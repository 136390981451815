import React, { useEffect, useState } from 'react';
import { Row, Col } from 'antd';
import { CheckboxValueType } from 'antd/lib/checkbox/Group';
import { FormButton } from 'common/components/Form/FormButtton';
import { BodyAreasCheckboxGroup } from 'common/components/Body/BodyAreasCheckboxGroup';
import { body } from 'common/const/bodyAreas.const';
import { IBaseParams, IBodyAnswer } from 'common/models/formBuilder.models';
import { EPatientGender } from 'common/const/patientInfo.const';
import bodyFemale from 'app/assets/images/bodyFemale.png';
import bodyMale from 'app/assets/images/bodyMale.png';
import {
  communicationPatientSessions,
  IPatientSessionsConnectedProps,
} from 'entities/PatientSessions/PatientSessions.communication';

interface IComponentProps {
  id: string;
  handleSubmit: (params: IBaseParams) => void;
}
type AllProps = IPatientSessionsConnectedProps & IComponentProps;

const InteractiveBodyAreasPage: React.FC<AllProps> = (props) => {
  const { patientSessionsModel, id, handleSubmit } = props;
  const [selectedParts, setSelectedParts] = useState<string[]>([]);
  const [answers, setAnswers] = useState<IBodyAnswer[]>([]);
  const { data, loading } = patientSessionsModel;
  const isFemale = data?.patient?.gender === EPatientGender.Female;

  const onChange = (checkedValues: CheckboxValueType[]): void => {
    setSelectedParts(checkedValues as string[]);
  };

  const getSelectedPartsImages = (): React.ReactNode => {
    return selectedParts.map((item) => {
      return isFemale ? body[item].imageFemale : body[item].imageMale;
    });
  };

  const onSubmit = () => {
    handleSubmit({ elements: [{ questionId: id, answers }] });
  };

  useEffect(() => {
    setAnswers(
      selectedParts.map((part: string) => ({
        part: part,
        detail: [],
      }))
    );
  }, [selectedParts]);

  return (
    <div className="body_areas__page">
      <Row gutter={[42, 42]} justify="center">
        <Col className="body_areas__page__body">
          <h1 className="body_areas__page__header">Click on Body Areas Where You Have Pain</h1>
          <div style={{ position: 'relative' }}>
            <img crossOrigin="anonymous" src={isFemale ? bodyFemale : bodyMale} alt="Patient body" />
            {getSelectedPartsImages()}
          </div>
        </Col>
        <Col className="body_areas__page__form">
          <BodyAreasCheckboxGroup onChange={onChange} />
        </Col>
      </Row>
      <Row justify="center" className="mt-8">
        <FormButton type="primary" disabled={!selectedParts.length || loading} onClick={onSubmit}>
          Next
        </FormButton>
      </Row>
    </div>
  );
};

export const BodyAreasPage = communicationPatientSessions.injector(InteractiveBodyAreasPage);
