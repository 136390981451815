import React from 'react';
import { Title } from 'common/components/TreatmentFeedback/Title';
import { ReactComponent as CheckMark } from 'app/assets/images/svg/checkMark.svg';

export const TreatmentFeedbackFinishPage: React.FC = () => {
  return (
    <div className="treatment-feedback">
      <div className="wrapper">
        <div className="content-card__container">
          <CheckMark />
          <Title>
            Your feedback has been submitted.
            <br />
            Thank you!
          </Title>
        </div>
      </div>
    </div>
  );
};
