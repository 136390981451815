import React from 'react';
import { Layout } from 'antd';
import { IPatientAnswer, IBaseParams, ICopyAnswersRequest } from 'common/models/formBuilder.models';
import { FormStatus } from 'common/components/Form/FormStatus';
import { TreatmentFeedbackStartPage } from 'common/components/TreatmentFeedback/TreatmentFeedbackStartPage';
import { TreatmentFeedbackForm } from 'common/components/TreatmentFeedback/TreatmentFeedbackForm';
import { TreatmentFeedbackFinishPage } from 'common/components/TreatmentFeedback/TreatmentFeedbackFinishPage';
import { DailyTreatmentSummaryMobile } from 'app/pages/DailyTreatmentSummaryMobile';
import { DiaryMedicationsMobile } from 'app/pages/Diary/Medications/DiaryMedicationsMobile';
import { BodyAreasPage } from 'app/pages/BodyAreasPage';
import { TableFormBuilder } from 'app/pages/TableFormBuilder';
import { PainAreasPage } from 'app/pages/PainAreasPage';
import { PromisPage } from 'app/pages/PromisPage';
import { DiaryOptionsPage } from 'app/pages/DiaryOptionsPage';
import { ThanksPage } from 'app/pages/ThanksPage';
import { PretherapyStartPage } from 'app/pages/PretherapyStart';
import { PretherapyFinishPage } from 'app/pages/PretherapyFinish';
import { DiaryFormBuilder } from 'app/pages/DiaryFormBuilder';
import { FormBuilder } from 'app/pages/FormBuilder';
import { DiaryThanksPage } from 'app/pages/Diary/DiaryThanksPage';
import { IQuestionnaireModel } from 'entities/PatientSessions/PatientSession.models';

interface IStatus {
  loading: boolean;
  success: boolean;
  errors: boolean;
}

export const getQuestionnaire = (
  questionnaire: IQuestionnaireModel,
  handleSubmit: (params: IBaseParams, update?: boolean) => void,
  getAnswersCopy: (params: ICopyAnswersRequest) => Promise<IQuestionnaireModel>,
  status: IStatus,
  patientAnswers: IPatientAnswer[],
  patientSessionId: string,
  getPrevPatientSessionsModel: (id: string) => void
): React.ReactNode => {
  const id = questionnaire.textId;
  const drawStatusBar = (message?: string) => {
    return <FormStatus loading={status.loading} errors={status.errors} success={status.success} message={message} />;
  };

  switch (id) {
    case 'baseline_common_body':
      return (
        <Layout className="layout">
          <BodyAreasPage id={id} handleSubmit={handleSubmit} />
        </Layout>
      );
    case 'baseline_body':
      return (
        <Layout className="layout">
          <PainAreasPage id={id} handleSubmit={handleSubmit} sessionId={patientSessionId} />
        </Layout>
      );
    case 'baseline_head':
    case 'baseline_body_left_arm':
    case 'baseline_body_right_arm':
    case 'baseline_body_left_hand':
    case 'baseline_body_right_hand':
    case 'baseline_body_chest_abdomen_back':
    case 'baseline_body_left_leg':
    case 'baseline_body_right_leg':
    case 'baseline_body_left_foot':
    case 'baseline_body_right_foot':
    case 'baseline_medical_history':
    case 'baseline_hit_six':
    case 'baseline_wpai_migraine':
    case 'baseline_msq_version_two_one':
    case 'baseline_phq_2':
    case 'baseline_gad_2':
    case 'baseline_taps_1':
    case 'baseline_demographics':
      return (
        <Layout className="layout">
          {drawStatusBar()}
          <FormBuilder
            questionnaire={questionnaire}
            handleSubmit={handleSubmit}
            patientAnswers={patientAnswers}
            patientSessionId={patientSessionId}
            getAnswersCopy={getAnswersCopy}
          />
        </Layout>
      );
    case 'baseline_pain_questions':
    case 'baseline_bed_time':
    case 'baseline_symptoms_list':
    case 'baseline_medications_list':
    case 'pretherapy_typical_pain_levels_expected_later_today':
    case 'pretherapy_symptoms_list':
      return (
        <Layout className="layout">
          {drawStatusBar()}
          <TableFormBuilder questionnaire={questionnaire} handleSubmit={handleSubmit} patientAnswers={patientAnswers} />
        </Layout>
      );
    case 'baseline_promis':
    case 'diary_promis':
      return (
        <Layout className="layout">
          {drawStatusBar()}
          <PromisPage questionnaire={questionnaire} handleSubmit={handleSubmit} />
        </Layout>
      );
    case 'baseline_diary_options':
      return (
        <Layout className="layout">
          {drawStatusBar()}
          <DiaryOptionsPage questionnaire={questionnaire} handleSubmit={handleSubmit} />
        </Layout>
      );
    case 'baseline_finish':
      return (
        <Layout className="layout">
          <ThanksPage questionnaire={questionnaire} handleSubmit={handleSubmit} />
        </Layout>
      );
    case 'pretherapy_start':
      return (
        <Layout className="layout">
          <PretherapyStartPage handleSubmit={handleSubmit} />
        </Layout>
      );
    case 'pretherapy_finish':
      return (
        <Layout className="layout">
          <PretherapyFinishPage />
        </Layout>
      );
    case 'diary_daily_treatment_summary':
      return (
        <Layout className="layout">
          {drawStatusBar()}
          <DailyTreatmentSummaryMobile questionnaire={questionnaire} handleSubmit={handleSubmit} />
        </Layout>
      );
    case 'diary_medications_list':
      return (
        <Layout className="layout">
          {drawStatusBar()}
          <DiaryMedicationsMobile
            questionnaire={questionnaire}
            handleSubmit={handleSubmit}
            patientSessionId={patientSessionId}
            getPrevPatientSessionsModel={getPrevPatientSessionsModel}
          />
        </Layout>
      );
    case 'diary_head_symptoms':
    case 'diary_chest_abdomen_back_symptoms':
    case 'diary_left_arm_symptoms':
    case 'diary_left_hand_symptoms':
    case 'diary_right_arm_symptoms':
    case 'diary_right_hand_symptoms':
    case 'diary_left_leg_symptoms':
    case 'diary_right_leg_symptoms':
    case 'diary_left_foot_symptoms':
    case 'diary_right_foot_symptoms':
    case 'diary_dvprs':
    case 'diary_blinding_questions':
    case 'diary_pgic':
    case 'diary_hit_six':
    case 'diary_wpai_migraine':
    case 'diary_msq_version_two_one':
    case 'diary_phq_2':
    case 'diary_gad_2':
    case 'diary_demographics':
    case 'diary_taps_1':
      return (
        <Layout className="layout">
          {drawStatusBar()}
          <DiaryFormBuilder questionnaire={questionnaire} handleSubmit={handleSubmit} patientAnswers={patientAnswers} />
        </Layout>
      );
    case 'diary_finish':
      return (
        <Layout className="layout">
          <DiaryThanksPage />
        </Layout>
      );
    case 'treatment_feedback_start':
      return <TreatmentFeedbackStartPage questionnaire={questionnaire} handleSubmit={handleSubmit} />;
    case 'treatment_feedback_30_min':
    case 'treatment_feedback_60_min':
    case 'treatment_feedback_120_min':
    case 'treatment_feedback_5_hours':
      return <TreatmentFeedbackForm questionnaire={questionnaire} handleSubmit={handleSubmit} />;
    case 'treatment_feedback_thank_you':
      return <TreatmentFeedbackFinishPage />;
    default:
      return <div>{drawStatusBar('Something went wrong. Please, reload the page and try again.')}</div>;
  }
};
