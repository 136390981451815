import React, { useMemo, useState } from 'react';
import { Divider } from 'antd';
import { IBaseParams, IPatientAnswer, IQuestionnaireAnswer } from 'common/models/formBuilder.models';
import { Title } from 'common/components/TreatmentFeedback/Title';
import { NavigationButton } from 'common/components/TreatmentFeedback/NavigationButton';
import { PainLevelSelector } from 'common/components/TreatmentFeedback/PainLevelSelector';
import { IRadioOption } from 'common/components/TreatmentFeedback/PainLevelRadioGroup';
import { IQuestionnaireModel } from 'entities/PatientSessions/PatientSession.models';

const numericPainValues: IRadioOption[] = [
  { label: '0', value: '0', bgColor: '#138424' },
  { label: '1', value: '1', bgColor: '#329A36' },
  { label: '2', value: '2', bgColor: '#6EB73F' },
  { label: '3', value: '3', bgColor: '#99C03A' },
  { label: '4', value: '4', bgColor: '#CECC09' },
  { label: '5', value: '5', bgColor: '#E2C913' },
  { label: '6', value: '6', bgColor: '#E2C913' },
  { label: '7', value: '7', bgColor: '#EC9915' },
  { label: '8', value: '8', bgColor: '#E26408' },
  { label: '9', value: '9', bgColor: '#CC4810' },
  { label: '10', value: '10', bgColor: '#D20B0D' },
];
const textPainValues: IRadioOption[] = [
  { label: 'None', value: 'none', bgColor: '#4B8944' },
  { label: 'Mild', value: 'mild', bgColor: '#E5C537' },
  { label: 'Moderate', value: 'moderate', bgColor: '#D67933' },
  { label: 'Severe', value: 'severe', bgColor: '#A2262A' },
];

interface IComponentProps {
  questionnaire: IQuestionnaireModel;
  patientAnswers?: IPatientAnswer[];
  handleSubmit: (params: IBaseParams, update?: boolean) => void;
}

export const TreatmentFeedbackForm: React.FC<IComponentProps> = ({ questionnaire, handleSubmit }) => {
  const [answers, setAnswers] = useState<IQuestionnaireAnswer[]>([]);
  // in case if we will be need to navigate Back and set answers for previous steps
  // const [patientAnswers, setPatientAnswers] = useState<IQuestionnaireAnswer[]>([]);
  const [isRemember, setIsRemember] = useState<boolean>(true);
  const { name, elements } = questionnaire;
  // symptom questions are 2-4 elements in array
  const symptoms = elements.slice(1);
  const period = useMemo(() => {
    const formName = questionnaire.name;
    const match = formName.match(/\(([^)]+)\)/);

    return match ? ` ${match[1]} ` : ' ';
  }, [questionnaire]);

  const onSubmit = () => {
    handleSubmit({ elements: answers });
  };

  const handlePainLevelSelect = (questionId: string, value: string | undefined) => {
    let updatedAnswers = [...answers];

    if (value) {
      const answerObject: IQuestionnaireAnswer = { questionId, answers: [value] };
      const existedAnswer = updatedAnswers.find((answer: IQuestionnaireAnswer) => answer.questionId === questionId);
      // If there is no previously answered elements, then there is no answer object
      if (existedAnswer) {
        existedAnswer.answers = [value];
      } else {
        updatedAnswers.push(answerObject);
      }
    } else {
      updatedAnswers = updatedAnswers.filter((updatedAnswer) => updatedAnswer.questionId !== questionId);
    }

    setAnswers(updatedAnswers);
  };

  const switchAnswersSet = () => {
    setIsRemember(!isRemember);
    // force handle answer for first question on switch
    handlePainLevelSelect(elements[0].textId, undefined);
  };

  // in case if we will be need to navigate Back and set answers for previous steps
  // useEffect(() => {
  //   // map patientAnswers to answers and set to form
  // }, [patientAnswers])

  return (
    <div className="treatment-feedback">
      <div className="wrapper">
        <div>
          <Title>{name}</Title>
          <div className="form__description">
            <span>Please tell us how you felt{period}after your treatment</span>
          </div>
          <div className="form__block__label">
            <span>{elements[0].question}</span>
          </div>
          <PainLevelSelector
            question={elements[0]}
            defaultGroupLabel="Select pain level"
            optionsGroup={isRemember ? numericPainValues : textPainValues}
            onSelect={handlePainLevelSelect}
            showNRSImage={isRemember}
          />
          <div onClick={switchAnswersSet} className="form__selector-switcher">
            <span>{isRemember ? "I don't remember" : 'Get back to numeric scale'}</span>
          </div>
          <Divider />
          <div className="form__block__label">
            <span>Symptom severity</span>
          </div>
          {symptoms.map((symptom) => (
            <PainLevelSelector
              key={symptom.textId}
              question={symptom}
              optionsGroup={textPainValues}
              onSelect={handlePainLevelSelect}
            />
          ))}
        </div>
        <div className="navigation-button-wrapper">
          <NavigationButton label="Submit" onClick={onSubmit} disabled={answers.length !== elements.length} />
        </div>
      </div>
    </div>
  );
};
