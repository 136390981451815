import React, { useEffect, useRef, useState } from 'react';
import { Button, Form, Layout } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import isEqual from 'lodash.isequal';
import {
  getQuestionTextForCheckboxLimitedQuestion,
  handle_baseline_head_27,
  handle_baseline_head_29,
  handle_baseline_head_30,
  handle_baseline_nonhead_25,
  handle_baseline_nonhead_26,
  renderFormControls,
} from 'common/helpers/formBuilder.helper';
import { ManageBtns } from 'common/components/Form/ManageBtns';
import { CopyAnswers } from 'common/components/Form/FormCopyAnswers';
import {
  IQuestionnaireAnswer,
  IFormValues,
  IPatientAnswer,
  ISelectGroupAnswer,
  IBaseParams,
  ICopyAnswersRequest,
} from 'common/models/formBuilder.models';
import { QUESTIONNAIRE_UPDATE_INTERVAL } from 'common/config';
import { answerIsObject } from 'common/utils/answerIsObject';
import { useTimer } from 'common/hooks/useTimer';
import { checkboxLimitedQuestionIds, ignoredQuestionTypesWithErrors } from 'common/const/questionnaire.const';
import {
  IAvailableNonHeadQuestionnaire,
  IQuestionnaireElement,
  IQuestionnaireModel,
} from 'entities/PatientSessions/PatientSession.models';

interface IComponentProps {
  questionnaire: IQuestionnaireModel;
  patientAnswers: IPatientAnswer[];
  handleSubmit: (params: IBaseParams, update?: boolean) => void;
  patientSessionId?: string;
  getAnswersCopy: (params: ICopyAnswersRequest) => Promise<IQuestionnaireModel>;
}

export const FormBuilder: React.FC<IComponentProps> = (props) => {
  const { questionnaire, handleSubmit, patientAnswers, patientSessionId, getAnswersCopy } = props;
  const [elements, setElements] = useState<IQuestionnaireElement[]>(questionnaire.elements);
  const [answers, setAnswers] = useState<IQuestionnaireAnswer[]>([]);
  const [answersCopy, setAnswersCopy] = useState<IQuestionnaireAnswer[]>(answers);
  const [additionalCheckboxValues, setAdditionalCheckboxValues] = useState<string[]>([]);
  const [showCopyAnswersModal, setShowCopyAnswersModal] = useState<boolean>(false);
  const [comment, setComment] = useState<string>('');
  const [commentCopy, setCommentCopy] = useState<string>(comment);
  const [formController] = useForm<IFormValues>();
  const [timer, timerLimit] = useTimer(QUESTIONNAIRE_UPDATE_INTERVAL);
  const titleRef = useRef<HTMLHeadingElement>(null);

  const addItemResponses = (question: string, value: string) => {
    const element = elements.find((item) => item.question === question);

    if (!element) {
      return;
    }

    if (additionalCheckboxValues.includes(value)) {
      return;
    }

    const answer = answers.find((answer: IQuestionnaireAnswer) => answer.questionId === element.textId);
    // If the custom item is added and there was no previously selected elements, then there is no answer object
    if (answer) {
      answer.answers.push(value);
    } else {
      answers.push({ questionId: element.textId, answers: [value] });
    }

    element.answers?.push(value);

    setAdditionalCheckboxValues((prev) => [...prev, value]);
    setElements([...elements]);
    setAnswers([...answers]);
  };

  const onChange = (values: IFormValues) => {
    const [answeredQuestionId, answeredValue] = Object.entries(values)[0];
    let updatedAnswers = [...answers];

    // ---> Handle head related questions with custom logic
    if (answeredQuestionId === 'baseline_head_27') {
      updatedAnswers = handle_baseline_head_27(formController, questionnaire, updatedAnswers, answeredValue);
    }

    if (answeredQuestionId === 'baseline_head_29') {
      updatedAnswers = handle_baseline_head_29(formController, questionnaire, updatedAnswers, answeredValue);
    }

    if (answeredQuestionId === 'baseline_head_30') {
      updatedAnswers = handle_baseline_head_30(formController, questionnaire, updatedAnswers, answeredValue);
    }
    // <---

    // ---> Handle nonhead related questions with custom logic
    if (
      answeredQuestionId.includes('baseline_body_') &&
      !answeredQuestionId.includes('_head_') &&
      answeredQuestionId.endsWith('_25')
    ) {
      updatedAnswers = handle_baseline_nonhead_25(
        formController,
        questionnaire,
        updatedAnswers,
        answeredQuestionId,
        answeredValue
      );

      setAnswers([...updatedAnswers]);

      // In this case we already have handled _25 question completely because of custom logic with 'None' value
      // so we don't need to go guther
      return;
    }

    if (
      answeredQuestionId.includes('baseline_body_') &&
      !answeredQuestionId.includes('_head_') &&
      answeredQuestionId.endsWith('_26')
    ) {
      updatedAnswers = handle_baseline_nonhead_26(
        formController,
        questionnaire,
        updatedAnswers,
        answeredQuestionId,
        answeredValue
      );
    }
    // <---

    const questionFromAnswered = updatedAnswers.find((item: IQuestionnaireAnswer) => item.questionId === answeredQuestionId);
    const questionFromQuestionnaire = questionnaire.elements.find((element) => element.textId === answeredQuestionId);

    // if question was answered earlier and there is its answer object
    if (questionFromAnswered) {
      // We should generate custom question text according to patient answers if it is question with type checkbox_limited
      const questionText = checkboxLimitedQuestionIds.includes(questionFromAnswered.questionId)
        ? getQuestionTextForCheckboxLimitedQuestion(answeredValue.length)
        : questionFromAnswered.question;

      questionFromAnswered.question = questionText;

      // in case of select group answer in questions when we set { "key": value }
      if (answerIsObject(answeredValue)) {
        // Exclude answeredValue from answers object if it's already in it
        questionFromAnswered.answers = questionFromAnswered.answers?.filter((element: ISelectGroupAnswer) =>
          Object.keys(element).join() === Object.keys(answeredValue).join() ? false : true
        );

        // Save new answeredValue
        questionFromAnswered.answers.push(answeredValue);
      } else {
        questionFromAnswered.answers = answeredValue;
      }
      // if question is answered at the first time
    } else if (questionFromQuestionnaire) {
      // We should generate custom question text according to patient answers if it is question with type checkbox_limited
      const questionText = checkboxLimitedQuestionIds.includes(questionFromQuestionnaire.textId)
        ? getQuestionTextForCheckboxLimitedQuestion(answeredValue.length)
        : questionFromQuestionnaire.question;

      updatedAnswers = [
        ...updatedAnswers,
        {
          questionId: answeredQuestionId,
          question: questionText,
          answers: answerIsObject(answeredValue) ? [answeredValue] : answeredValue,
        },
      ];
    }

    setAnswers([...updatedAnswers]);
  };

  const handleSave = (comment: string) => {
    if (comment) {
      setComment(comment);
    }
  };

  const onSubmit = () => {
    handleSubmit({ elements: answers, comments: [{ textId: questionnaire.textId, comment }] });
  };

  const toggleCopyAnswersModal = () => {
    setShowCopyAnswersModal(!showCopyAnswersModal);
  };

  const handlePrefill = (value: string) => {
    const questionnaireId = questionnaire.prefillOptions?.find(
      (questionnaire: IAvailableNonHeadQuestionnaire) => questionnaire.name === value
    )?.textId;

    if (patientSessionId && questionnaireId) {
      getAnswersCopy({ id: patientSessionId, textId: questionnaireId }).then(() => {
        toggleCopyAnswersModal();
      });
    }
  };

  useEffect(() => {
    if (timer === timerLimit && (!isEqual(answers, answersCopy) || !isEqual(comment, commentCopy))) {
      const fieldsWithErrors = formController
        .getFieldsError()
        .filter((field) => field.errors.length)
        .map((field) => field.name)
        .flat();

      const elements = answers.filter((answer: IQuestionnaireAnswer) => {
        const questionType =
          questionnaire.elements.find((element: IQuestionnaireElement) => element.textId === answer.questionId)?.type || '';

        return ignoredQuestionTypesWithErrors.includes(questionType) ? true : !fieldsWithErrors.includes(answer.questionId);
      });

      handleSubmit({ elements, comments: [{ textId: questionnaire.textId, comment }] }, true);
      setAnswersCopy(answers);
      setCommentCopy(comment);
    }
  }, [timer]);

  useEffect(() => {
    const answers: IQuestionnaireAnswer[] = [];

    if (patientAnswers.length) {
      patientAnswers.forEach((patientAnswer: IPatientAnswer) => {
        // Name will be an array only in case when it's a FormSelectGroup component answers
        const patientAnswerFieldName = patientAnswer.name instanceof Array ? patientAnswer.name[0] : patientAnswer.name;
        const question = questionnaire.elements.find(
          (element: IQuestionnaireElement) => element.textId === patientAnswerFieldName
        );
        // We should generate custom question text according to patient answers if it is question with type checkbox_limited
        const questionText = question
          ? checkboxLimitedQuestionIds.includes(question.textId)
            ? getQuestionTextForCheckboxLimitedQuestion(patientAnswer.value.length)
            : question.question
          : '';

        // Name will be an array only in case when it's a FormSelectGroup component answers
        if (patientAnswer.name instanceof Array) {
          const answer = answers.find((item: IQuestionnaireAnswer) => item.questionId === patientAnswer.name[0]);
          const value = { [patientAnswer.name[1]]: patientAnswer.value };

          if (answer) {
            answer.answers.push(value);
            answer.question = questionText;
          } else {
            answers.push({ questionId: patientAnswer.name[0], answers: [value], question: questionText });
          }
        } else {
          answers.push({
            questionId: patientAnswer.name,
            answers: patientAnswer.value,
            question: questionText,
          });
        }
      });

      setAnswers(answers);
    }
  }, [patientAnswers]);

  useEffect(() => {
    if (questionnaire.name && titleRef.current) {
      titleRef.current.innerHTML = `${questionnaire.name}`;
    }
  }, [questionnaire.name]);

  return (
    <Layout>
      <Form
        className="form"
        layout="vertical"
        onFinish={onSubmit}
        onValuesChange={onChange}
        scrollToFirstError={{ behavior: 'smooth' }}
        fields={patientAnswers}
        preserve={false}
        form={formController}
      >
        <h1 className="form__title" ref={titleRef} />
        {questionnaire.prefillOptions && (
          <Button className="mb-6" onClick={toggleCopyAnswersModal}>
            Copy answers from ...
          </Button>
        )}

        {renderFormControls(elements, answers, addItemResponses, additionalCheckboxValues, formController)}

        <ManageBtns handleSave={handleSave} />
      </Form>
      {questionnaire?.prefillOptions && (
        <CopyAnswers
          visible={showCopyAnswersModal}
          onCancel={toggleCopyAnswersModal}
          onSave={() => setShowCopyAnswersModal(!showCopyAnswersModal)}
          questionnareTitle={questionnaire.name}
          options={questionnaire.prefillOptions.map((entry: IAvailableNonHeadQuestionnaire) => entry.name)}
          handlePrefill={handlePrefill}
        />
      )}
    </Layout>
  );
};
