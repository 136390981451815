export const enum EHeadNeck {
  'Head/Neck (Front)' = 'Head/Neck (Front)',
  'Head/Neck (Back)' = 'Head/Neck (Back)',
  'Head/Neck (Right)' = 'Head/Neck (Right)',
  'Head/Neck (Left)' = 'Head/Neck (Left)',
  'Head/Neck (Top)' = 'Head/Neck (Top)',
}

export const enum ERightArm {
  'Right Arm (Front)' = 'Right Arm (Front)',
  'Right Arm (Back)' = 'Right Arm (Back)',
}

export const enum ELeftArm {
  'Left Arm (Front)' = 'Left Arm (Front)',
  'Left Arm (Back)' = 'Left Arm (Back)',
}

export const enum ERightHand {
  'Right Hand (Front)' = 'Right Hand (Front)',
  'Right Hand (Back)' = 'Right Hand (Back)',
}

export const enum ELeftHand {
  'Left Hand (Front)' = 'Left Hand (Front)',
  'Left Hand (Back)' = 'Left Hand (Back)',
}

export const enum EChestAbdomenBack {
  'Chest/Abdomen' = 'Chest/Abdomen',
  'Back' = 'Back',
  'Left' = 'Left',
  'Right' = 'Right',
}

export const enum ELeftLeg {
  'Left Leg (Front)' = 'Left Leg (Front)',
  'Left Leg (Back)' = 'Left Leg (Back)',
  'Left Leg (Side)' = 'Left Leg (Side)',
}

export const enum ERightLeg {
  'Right Leg (Front)' = 'Right Leg (Front)',
  'Right Leg (Back)' = 'Right Leg (Back)',
  'Right Leg (Side)' = 'Right Leg (Side)',
}

export const enum ELeftFoot {
  'Left Foot (Front)' = 'Left Foot (Front)',
  'Left Foot (Back)' = 'Left Foot (Back)',
}

export const enum ERightFoot {
  'Right Foot (Front)' = 'Right Foot (Front)',
  'Right Foot (Back)' = 'Right Foot (Back)',
}
