import React from 'react';
import { IBodyParts } from 'common/models/body.models';
import MaleHeadOutlined from 'app/assets/images/bodyAreas/maleOutlined/maleHeadOutlined.png';
import MaleChestOutlined from 'app/assets/images/bodyAreas/maleOutlined/maleChestOutlined.png';
import MaleLeftLegOutlined from 'app/assets/images/bodyAreas/maleOutlined/maleLeftLegOutlined.png';
import MaleRightLegOutlined from 'app/assets/images/bodyAreas/maleOutlined/maleRightLegOutlined.png';
import MaleLeftFootOutlined from 'app/assets/images/bodyAreas/maleOutlined/maleLeftFootOutlined.png';
import MaleRightFootOutlined from 'app/assets/images/bodyAreas/maleOutlined/maleRightFootOutlined.png';
import MaleLeftArmOutlined from 'app/assets/images/bodyAreas/maleOutlined/maleLeftArmOutlined.png';
import MaleRightArmOutlined from 'app/assets/images/bodyAreas/maleOutlined/maleRightArmOutlined.png';
import MaleLeftHandOutlined from 'app/assets/images/bodyAreas/maleOutlined/maleLeftHandOutlined.png';
import MaleRightHandOutlined from 'app/assets/images/bodyAreas/maleOutlined/maleRightHandOutlined.png';
import FemaleHeadOutlined from 'app/assets/images/bodyAreas/femaleOutlined/femaleHeadOutlined.png';
import FemaleChestOutlined from 'app/assets/images/bodyAreas/femaleOutlined/femaleChestOutlined.png';
import FemaleLeftLegOutlined from 'app/assets/images/bodyAreas/femaleOutlined/femaleLeftLegOutlined.png';
import FemaleRightLegOutlined from 'app/assets/images/bodyAreas/femaleOutlined/femaleRightLegOutlined.png';
import FemaleLeftFootOutlined from 'app/assets/images/bodyAreas/femaleOutlined/femaleLeftFootOutlined.png';
import FemaleRightFootOutlined from 'app/assets/images/bodyAreas/femaleOutlined/femaleRightFootOutlined.png';
import FemaleLeftArmOutlined from 'app/assets/images/bodyAreas/femaleOutlined/femaleLeftArmOutlined.png';
import FemaleRightArmOutlined from 'app/assets/images/bodyAreas/femaleOutlined/femaleRightArmOutlined.png';
import FemaleLeftHandOutlined from 'app/assets/images/bodyAreas/femaleOutlined/femaleLeftHandOutlined.png';
import FemaleRightHandOutlined from 'app/assets/images/bodyAreas/femaleOutlined/femaleRightHandOutlined.png';

export enum ECommonBodyParts {
  HeadNeck = 'head_neck',
  RightArm = 'right_arm',
  LeftArm = 'left_arm',
  RightHand = 'right_hand',
  LeftHand = 'left_hand',
  ChestAbdomenBack = 'chest_abdomen_back',
  RightLeg = 'right_leg',
  LeftLeg = 'left_leg',
  RightFoot = 'right_foot',
  LeftFoot = 'left_foot',
}

export const body: IBodyParts = {
  [ECommonBodyParts.HeadNeck]: {
    title: 'Head / Neck',
    value: ECommonBodyParts.HeadNeck,
    imageMale: (
      <img
        crossOrigin="anonymous"
        src={MaleHeadOutlined}
        key={ECommonBodyParts.HeadNeck}
        className="body_areas__page__body__part body_areas__page__body__head"
      />
    ),
    imageFemale: (
      <img
        crossOrigin="anonymous"
        src={FemaleHeadOutlined}
        key={ECommonBodyParts.HeadNeck}
        className="body_areas__page__body__part body_areas__page__body__head--female"
      />
    ),
  },
  [ECommonBodyParts.RightArm]: {
    title: 'Right Arm',
    value: ECommonBodyParts.RightArm,
    imageMale: (
      <img
        crossOrigin="anonymous"
        src={MaleRightArmOutlined}
        key={ECommonBodyParts.RightArm}
        className="body_areas__page__body__part body_areas__page__body__right_arm"
      />
    ),
    imageFemale: (
      <img
        crossOrigin="anonymous"
        src={FemaleRightArmOutlined}
        key={ECommonBodyParts.RightArm}
        className="body_areas__page__body__part body_areas__page__body__right_arm--female"
      />
    ),
  },
  [ECommonBodyParts.LeftArm]: {
    title: 'Left Arm',
    value: ECommonBodyParts.LeftArm,
    imageMale: (
      <img
        crossOrigin="anonymous"
        src={MaleLeftArmOutlined}
        key={ECommonBodyParts.LeftArm}
        className="body_areas__page__body__part body_areas__page__body__left_arm"
      />
    ),
    imageFemale: (
      <img
        crossOrigin="anonymous"
        src={FemaleLeftArmOutlined}
        key={ECommonBodyParts.LeftArm}
        className="body_areas__page__body__part body_areas__page__body__left_arm--female"
      />
    ),
  },
  [ECommonBodyParts.RightHand]: {
    title: 'Right Hand',
    value: ECommonBodyParts.RightHand,
    imageMale: (
      <img
        crossOrigin="anonymous"
        src={MaleRightHandOutlined}
        key={ECommonBodyParts.RightHand}
        className="body_areas__page__body__part body_areas__page__body__right_hand"
      />
    ),
    imageFemale: (
      <img
        crossOrigin="anonymous"
        src={FemaleRightHandOutlined}
        key={ECommonBodyParts.RightHand}
        className="body_areas__page__body__part body_areas__page__body__right_hand--female"
      />
    ),
  },
  [ECommonBodyParts.LeftHand]: {
    title: 'Left Hand',
    value: ECommonBodyParts.LeftHand,
    imageMale: (
      <img
        crossOrigin="anonymous"
        src={MaleLeftHandOutlined}
        key={ECommonBodyParts.LeftHand}
        className="body_areas__page__body__part body_areas__page__body__left_hand"
      />
    ),
    imageFemale: (
      <img
        crossOrigin="anonymous"
        src={FemaleLeftHandOutlined}
        key={ECommonBodyParts.LeftHand}
        className="body_areas__page__body__part body_areas__page__body__left_hand--female"
      />
    ),
  },
  [ECommonBodyParts.ChestAbdomenBack]: {
    title: 'Chest/Abdomen/Back',
    value: ECommonBodyParts.ChestAbdomenBack,
    imageMale: (
      <img
        crossOrigin="anonymous"
        src={MaleChestOutlined}
        key={ECommonBodyParts.ChestAbdomenBack}
        className="body_areas__page__body__part body_areas__page__body__chest"
      />
    ),
    imageFemale: (
      <img
        crossOrigin="anonymous"
        src={FemaleChestOutlined}
        key={ECommonBodyParts.ChestAbdomenBack}
        className="body_areas__page__body__part body_areas__page__body__chest--female"
      />
    ),
  },
  [ECommonBodyParts.LeftLeg]: {
    title: 'Left Leg',
    value: ECommonBodyParts.LeftLeg,
    imageMale: (
      <img
        crossOrigin="anonymous"
        src={MaleLeftLegOutlined}
        key={ECommonBodyParts.LeftLeg}
        className="body_areas__page__body__part body_areas__page__body__left__leg"
      />
    ),
    imageFemale: (
      <img
        crossOrigin="anonymous"
        src={FemaleLeftLegOutlined}
        key={ECommonBodyParts.LeftLeg}
        className="body_areas__page__body__part body_areas__page__body__left__leg--female"
      />
    ),
  },
  [ECommonBodyParts.RightLeg]: {
    title: 'Right Leg',
    value: ECommonBodyParts.RightLeg,
    imageMale: (
      <img
        crossOrigin="anonymous"
        src={MaleRightLegOutlined}
        key={ECommonBodyParts.RightLeg}
        className="body_areas__page__body__part body_areas__page__body__right__leg"
      />
    ),
    imageFemale: (
      <img
        crossOrigin="anonymous"
        src={FemaleRightLegOutlined}
        key={ECommonBodyParts.RightLeg}
        className="body_areas__page__body__part body_areas__page__body__right__leg--female"
      />
    ),
  },
  [ECommonBodyParts.LeftFoot]: {
    title: 'Left Foot',
    value: ECommonBodyParts.LeftFoot,
    imageMale: (
      <img
        crossOrigin="anonymous"
        src={MaleLeftFootOutlined}
        key={ECommonBodyParts.LeftFoot}
        className="body_areas__page__body__part body_areas__page__body__left__foot"
      />
    ),
    imageFemale: (
      <img
        crossOrigin="anonymous"
        src={FemaleLeftFootOutlined}
        key={ECommonBodyParts.LeftFoot}
        className="body_areas__page__body__part body_areas__page__body__left__foot--female"
      />
    ),
  },
  [ECommonBodyParts.RightFoot]: {
    title: 'Right Foot',
    value: ECommonBodyParts.RightFoot,
    imageMale: (
      <img
        crossOrigin="anonymous"
        src={MaleRightFootOutlined}
        key={ECommonBodyParts.RightFoot}
        className="body_areas__page__body__part body_areas__page__body__right__foot"
      />
    ),
    imageFemale: (
      <img
        crossOrigin="anonymous"
        src={FemaleRightFootOutlined}
        key={ECommonBodyParts.RightFoot}
        className="body_areas__page__body__part body_areas__page__body__right__foot--female"
      />
    ),
  },
};
