import React from 'react';
import { IBaseParams, IPatientAnswer } from 'common/models/formBuilder.models';
import { Title } from 'common/components/TreatmentFeedback/Title';
import { NavigationButton } from 'common/components/TreatmentFeedback/NavigationButton';
import { ReactComponent as ThermaquilLogo } from 'app/assets/images/svg/thermaquilLogo.svg';
import { IQuestionnaireModel } from 'entities/PatientSessions/PatientSession.models';

interface IComponentProps {
  questionnaire: IQuestionnaireModel;
  patientAnswers?: IPatientAnswer[];
  handleSubmit: (params: IBaseParams, update?: boolean) => void;
}

export const TreatmentFeedbackStartPage: React.FC<IComponentProps> = ({ handleSubmit }) => {
  const onSubmit = () => {
    handleSubmit({ elements: [] });
  };

  return (
    <div className="treatment-feedback">
      <div className="wrapper">
        <div>
          <div className="main-logo">
            <ThermaquilLogo />
          </div>
          <Title>Please provide feedback on your today&apos;s treatment with the Thermaquil device</Title>
          <div className="content-card__container">
            <span className="content-card__text">
              You will be asked to share your pain levels and symptom severity experienced at 30, 60, 120 minutes, and 5 hours
              after your treatment. Each page will inquire about your treatment feedback for a specific time period. Once
              you&apos;ve filled in the data for one time period, click &quot;Submit&quot; to move on to the next.
            </span>
          </div>
        </div>
        <div className="navigation-button-wrapper">
          <NavigationButton label="Next" onClick={onSubmit} />
        </div>
      </div>
    </div>
  );
};
