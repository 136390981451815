import { Button, ButtonProps } from 'antd';
import React from 'react';

interface IComponentProps extends ButtonProps {
  label: string;
  onClick: () => void;
}

export const NavigationButton: React.FC<IComponentProps> = ({ label, onClick, ...props }) => {
  const { disabled } = props;

  return (
    <Button className="navigation-button" onClick={onClick} disabled={disabled} style={disabled ? { opacity: 0.5 } : {}}>
      {label}
    </Button>
  );
};
