import React from 'react';
import { Radio } from 'antd';
import type { RadioChangeEvent } from 'antd';

export interface IRadioOption {
  label: string;
  value: string;
  bgColor: string;
}

interface IComponentProps {
  handleChange: (answer: string) => void;
  radioOptions: IRadioOption[];
  submittedAnswer?: string;
}

export const PainLevelRadioGroup: React.FC<IComponentProps> = ({ radioOptions, handleChange, submittedAnswer }) => {
  const onChange = (e: RadioChangeEvent) => {
    handleChange(e.target.value);
  };

  return (
    <div className="mt-8">
      <Radio.Group onChange={onChange} className="pain-level-selector__radio-group" defaultValue={submittedAnswer}>
        {radioOptions.map((radioOption, index) => (
          <Radio
            key={index}
            value={radioOption.value}
            className="pain-level-selector__radio-group__item"
            style={{ backgroundColor: radioOption.bgColor }}
          >
            {radioOption.label}
          </Radio>
        ))}
      </Radio.Group>
    </div>
  );
};
